.popup {
    background: white;
    padding: 1em;
    border-radius: .2em;
    display: inline-block;
    position: absolute;
    bottom: 3em;
    right: 0;
    margin: 1em;
    width: 55%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .15);
}

@media only screen and (max-width: 767px) {
    .popup {
        width: calc(100% - 2em);
    }
}

.popup.bottom-right {
    bottom: 3em;
    right: 0;
}

.popup.bottom-left {
    bottom: 3em;
    left: 0;
}

.popup.top-right {
    top: 0em;
    right: 0;
    bottom: inherit;
}