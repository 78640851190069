.disabled-background {
    position: fixed;
    width: 100%;
    height: 100%;
}

.disabled-background img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.disabled-message-container {
    position: absolute;
    bottom:0;
    width: 100%;
}

.ui.message.disabled-message {
    color: #01181e;
    background-color: #f8ffffba;
    margin: 0 auto;
    max-width: 90%;
    margin-bottom: 10vh !important;
}


@media only screen and (min-width: 768px) {
    .ui.message.disabled-message {
        max-width: 700px;
    }
  }