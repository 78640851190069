.progress-slider {
    align-self: center;
    margin: 0 0 0 .25em;
    flex: 1;
    height: 6px;
    border-radius: 2px;
    background: #eeeeee80;
    transition: width 0.4s;
    cursor: pointer;
}

.progress-slider.progress-disabled {
    cursor: default;
}

.progress-slider-bar {
    /* background-color: #fafafa8e; */
    background-color: #fffd;
    position: absolute;
    border-radius: 2.5px;
    display: inline-block;
    top: 0;
    bottom: 0;
    left: 0;
    transition: background-color .1s ease;
    min-width: 3px;
}

.progress-slider-bar:hover {
    background-color: #2185d0;
}

.progress-slider:hover .progress-slider-bar {
    background-color: #2185d0;
}

 /*.progres-slider-bar.progress-loading {
    background-color: #a4a4a4;
} */

.progress-slider-bar.progress-loading {
    background-image: 
      repeating-linear-gradient(
        -45deg, 
        transparent, 
        transparent .4rem,
        #00000012 .4rem,
        #00000012 .8rem
      );
    background-size: 200% 200%;
    animation: barberpole 20s linear infinite;
  }
  
  @keyframes barberpole {
    100% {
      background-position: 100% 100%;
    }
  }

.progress-slider-handle {
    background-color: white;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    top: 0;
    margin-top: -3px;
    margin-left: -1px;
    box-shadow: 0 0 4px #0008;
    opacity: 0;
    

    transition: opacity .2s ease-out;
    transition-delay: 1s;
}

.progress-slider-handle:hover,
.progress-slider:hover .progress-slider-handle {
    opacity: 1;
    transition: opacity .1s ease-out;
    transition-delay: 0;
}

.progress-slider-buffered {
    background-color: #fafafa5e;
    position: absolute;
    border-radius: 2.5px;
    display: inline-block;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 3px;
}