.weather-popup .ui.segments.horizontal {
    margin-top: .25em;
    margin-bottom: .75em;
}

.weather-popup {
    display: inline-block;
    position: absolute;
    bottom: 3em;
    right: 0;
    margin: 1em .75em;
    width: 60%;
    padding: 0 .25em .25em .25em;
}

.weather-popup .ui.labels>.label:last-of-type {
    margin-right: 0;
}
.weather-popup .ui.labels>.label {
    margin-bottom: 0;
}

.weather-popup .ui.horizontal.segments {
    background: rgba(255,255,255,.55);
}

.weather-icon {
    font-size: 2em;
}

.weather-temperature {
    font-size: 1em !important;
    white-space: nowrap;
}

.weather-time {
    color: gray;
    line-height: 1em;
    padding: .5em 0 .9em 0;
}

@media only screen and (max-width: 767px) {
    .weather-popup {
        width: calc(100% - 1em);
        bottom: 3em;
        margin: .5em;
    }
    .weather-icon {
        font-size: 1.5em;
    }
    .weather-time {
        font-size: .8em;
    }

    .weather-temperature {
        font-size: .85714286rem !important;
    }

    .weather-popup .ui.segments {
        padding: .5em .5em;
    }
    .weather-popup .ui.segment {
        padding: .8em .2em;
    }
}