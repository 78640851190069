.bottom-stip {
    height: 2.5rem;
    width: 100%;
    background: #2185d0;
    color: white;
    position: absolute;
    bottom: 0;
    display: flex;
    padding: 0 1em;
}

@media only screen and (max-width: 767px) {
    .bottom-stip {
        padding: 0 .5em;
    }
}

.bottom-stip a, .news-stip a:hover {
    color: white;
}

.bottom-stip a:hover {
    text-decoration: underline !important;
}

.camera-location {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.marquee3k {
    overflow: hidden;
    display: flex;
    align-items: center;
}

.marquee3k .separator {
    margin: 0 .5em;
}

@media only screen and (max-width: 767px) {
    .camera-location .ui.label>.icon {
        margin: 0;
    }
}