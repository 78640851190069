.player-wrapper {
    position: fixed; 
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
}

.video-wrapper {
    width: 100%;
    height: 100%;
}

.controls {
    background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.6));
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 1em;
}

.title {
    color: white;
    text-shadow: 1px 1px rgba(0,0,0,.7);
    padding: 1em;
    background: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,0));
}

.overlay-wrapper {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
}

/* .has-news.has-progress .overlay-wrapper {
    height: calc(100% - 4rem);
} */

.progress-loading {
    background: red
}


.has-news .overlay-wrapper {
    height: calc(100% - 2.5rem);
}

.has-news .video-wrapper {
    height: calc(100% - 2.5rem);
}
.has-news video {
    min-height: 100%;
}

.playing-text {
    color: white;
    text-shadow: 1px 1px rgba(0,0,0,.7);
}

.centered-button .ui.blue.button {
    box-shadow: 0px 3px 3px 0 rgba(34,36,38,.3);
}

img.image-widget {
    position: absolute !important;
    margin: 1em;
}

img.image-widget.top-left {
    top: 0;
}

img.image-widget.top-right {
    top: 0;
    right: 0;
}

img.image-widget.bottom-right {
    bottom: 3.5em;
    right: 0;
}

img.image-widget.bottom-left {
    bottom: 3.5em;
    left: 0;
}


@media only screen and (max-width: 767px) {
    .loading-label {
        display: none !important;
    }
    .controls {
        padding: .5em;
    }
}

.video-fit-cover {
    overflow: hidden;
}

.video-fit-cover video {
    object-fit: cover;
}

.video-fit-cover img.player-replacement-thumbnail {
    object-fit: cover;
}

.player-replacement-thumbnail {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.duration-text {
    color: white;
    text-shadow: 0 0 2px #000;
    background: #0003;
    border-radius: .2em;
    font-size: .8em;
    margin-right: 1em;
    padding: 1px 3px;
    line-height: initial;
    height: max-content;
}
.progress-controls {
    display: flex;
    height: 20px;
    padding: 0 .7em;
    align-items: center;
    margin-top: 9px;
}

.debug-box {
    z-index:99;
    position: absolute;
    top: 2em;
    right: 2em;
    color: white;
    background-color: #0005;
    padding: .7em;
    width: 280px;
}
.debug-box ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 80%;
}

.debug-box .close-icon {
    color: gray;
    position: absolute;
    right: 4px;
    cursor: pointer;
    top: -4px;
    font-size: .8em;
}

.debug-box .value {
    float: right;
    user-select: all;
}