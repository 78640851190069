body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html, body, #root {
  height: 100%;
  background-color: black;
  transition: background-color .4s;
}

.centered-button {
  z-index: 99;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%,-50%);
}

.error-container {
  padding-top: 45vh;
}

.login-container {
  padding-top: 25vh;
}


@media only screen and (max-width: 767px) {
  .mobile-hide {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .hidden.mobile-only {
      display: none !important;
  }
}